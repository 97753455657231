import React from 'react';
import {Link} from 'react-router-dom';

const WidgetPost = ({blogReadings}) => {
    return (
        <div className="post-list-wrap">

            {blogReadings.map((data, index) => (
                <div className="single-post" key={index}>
                    <div className="post-thumbnail">
                        <Link to={data.url} target="_blank">
                            <img src={"/images/blog/" + data.thumbnail || "/images/blog/thumb_5.png"} alt="Blog"/>
                        </Link>
                    </div>
                    <div className="post-content">
                        <h6 className="title">
                            <Link to={data.url} target="_blank">
                                {data.title}
                            </Link>
                        </h6>
                        <ul className="blog-meta list-unstyled">
                            <li>{data.count} stories</li>
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default WidgetPost;


