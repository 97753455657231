import React, {useEffect, useState} from 'react';
import HeaderOne from '../common/header/HeaderOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServiceProp from '../component/service/ServiceProp';
import BreadCrumbResume from "../elements/breadcrumb/BreadCrumbResume";
import FooterTwo from "../common/footer/FooterTwo";
import {fetchFirestoreData} from "../common/DataFetcher";
import LoadingSpinner from "../common/LoadingSpinner";
import "../assets/custom-nara.css"
import ScrollToTopButton from "../common/ScrollToTopButton";

const ServiceOne = () => {
    const [resumeHeader, setResumeHeaders] = useState([]);
    const [resumeExperience, setResumeExperience] = useState([]);
    const [resumeQualification, setResumeQualification] = useState([]);
    const [resumeCommunity, setResumeCommunity] = useState([]);

    // Fetch data
    useEffect(() => {
        const unsubscribe = fetchFirestoreData('resume_header', setResumeHeaders);
        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []);

    useEffect(() => {
        const unsubscribe = fetchFirestoreData('resume_experience', setResumeExperience, 'order', 'published');
        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []);

    useEffect(() => {
        const unsubscribe = fetchFirestoreData('resume_qualification', setResumeQualification, 'order', 'published');
        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []);

    useEffect(() => {
        const unsubscribe = fetchFirestoreData('resume_community', setResumeCommunity, 'order', 'published');
        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []);

    return (
        <>
            <SEO
                title={`Resume - ${process.env.REACT_APP_OWNER}`}
                name={process.env.REACT_APP_OWNER}
                description={process.env.REACT_APP_SEO_DESCRIPTION}
                keywords={process.env.REACT_APP_SEO_KEYWORDS}
                author={`${process.env.REACT_APP_OWNER} | ${process.env.SEO_TAG}`}
                image={process.env.REACT_APP_SEO_IMAGE}
            />
            <ColorSwitcher/>
            <ScrollToTopButton/>
            <main className="main-wrapper">
                <HeaderOne/>
                {resumeHeader.length > 0 ? (
                    resumeHeader.map((item, index) => (
                        <div key={index}>
                            <BreadCrumbResume
                                headerDetails={item}
                            />
                        </div>
                    ))
                ) : (
                    <LoadingSpinner/> // Show loading text if no data is fetched
                )}
                <div>
                    <div className="service-scroll-navigation-area">
                        <div className="section custom-section" id="section1">
                            <div className="container">
                                <SectionTitle
                                    title="Professional Experience"
                                    textAlignment="heading-left"
                                />
                                <div className="row">
                                    {resumeExperience.length > 0 ? (
                                        <ServiceProp colSize="col-lg-12 col-md-12" serviceStyle="service-style-2"
                                                     serviceData={resumeExperience}/>
                                    ) : (
                                        <LoadingSpinner/> // Show loading text if no data is fetched
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="section custom-section" id="section2">
                            <div className="container">
                                <SectionTitle
                                    title="Education & Credentials"
                                    textAlignment="heading-left"
                                />
                                <div className="row">
                                    {resumeQualification.length > 0 ? (
                                        <ServiceProp colSize="col-lg-4 col-md-12" serviceStyle="service-style-2"
                                                     serviceData={resumeQualification}/>
                                    ) : (
                                        <LoadingSpinner/> // Show loading text if no data is fetched
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="section custom-section" id="section3">
                            <div className="container">
                                <SectionTitle
                                    title="Leadership & Community"
                                    textAlignment="heading-left"
                                />
                                <div className="row">
                                    {resumeCommunity.length > 0 ? (
                                        <ServiceProp colSize="col-lg-4 col-md-12" serviceStyle="service-style-2"
                                                     serviceData={resumeCommunity}/>
                                    ) : (
                                        <LoadingSpinner/> // Show loading text if no data is fetched
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FooterTwo parentClass=""/>
            </main>
        </>
    )
}

export default ServiceOne;