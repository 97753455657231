import React, {useEffect, useState} from 'react';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CaseStudyProp from '../component/casestudy/CaseStudyProp';
import FooterTwo from "../common/footer/FooterTwo";
import {fetchFirestoreData} from "../common/DataFetcher";
import LoadingSpinner from "../common/LoadingSpinner";
import ScrollToTopButton from "../common/ScrollToTopButton";


const CaseStudy = () => {
    const [workSummary, setWorkSummary] = useState([]);
    // Fetch "workSummary" data
    useEffect(() => {
        const unsubscribe = fetchFirestoreData('work_summary', setWorkSummary, 'order', 'published');
        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };

    }, []);

    const headerImageSrc = "/images/banner/work-summary-thumbnail.png";

    return (
        <>
            <SEO
                title={`Professional Highlights - ${process.env.REACT_APP_OWNER}`}
                name={process.env.REACT_APP_OWNER}
                description={process.env.REACT_APP_SEO_DESCRIPTION}
                keywords={process.env.REACT_APP_SEO_KEYWORDS}
                author={`${process.env.REACT_APP_OWNER} | ${process.env.SEO_TAG}`}
                image={process.env.REACT_APP_SEO_IMAGE}
            />
            <ColorSwitcher/>
            <ScrollToTopButton/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BcrumbBannerOne
                    title="Professional Highlights"
                    paragraph="Key projects where I’ve driven impact and delivered results across industries."
                    styleClass=""
                    mainThumb={headerImageSrc || "/images/banner/banner-thumb-1.png"}
                />
                <div className="pt--250 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area">
                    <div className="container">
                        {workSummary.length > 0 ? (
                            <CaseStudyProp workSummaryData={workSummary}/>
                        ) : (
                            <LoadingSpinner/> // Show loading text if no data is fetched
                        )}
                    </div>
                </div>
                <FooterTwo/>
            </main>


        </>
    )
}


export default CaseStudy;