import React from 'react';
import "../../assets/custom-nara.css"

const ServicePropOne = ({colSize, serviceStyle, itemShow, marginTop, servicesList}) => {

    const topMargin = (index) => {
        if (marginTop === "yes") {
            if (index === 0) {
                return "mt--200 mt_md--0";
            } else if (index === 1) {
                return "mt--100 mt_md--0";
            } else {
                return "mt--0";
            }
        } else {
            return "";
        }
    }

    return (
        <>
            {servicesList.slice(0, itemShow).map((data, index) => (
                <div className={`${colSize} ${topMargin(index)}`} key={index}>
                    <div className={`services-grid ${serviceStyle} custom-nara-service-grid`}>
                        <div className="thumbnail">
                            <img src={process.env.PUBLIC_URL + "/images/icon/" + data.img} alt="icon"/>
                        </div>
                        <div className="content ">
                            <h5 className="title custom-nara-service-title">{data.service}</h5>
                            <p className="custom-nara-services-content" dangerouslySetInnerHTML={{__html: data.description}}></p>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default ServicePropOne;