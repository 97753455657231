// DataExtractor.js

/**
 * Extracts the first image src from an HTML string.
 * @param {string} description - The HTML string containing the description.
 * @returns {string|null} - The src attribute of the first img tag, or null if none found.
 */
export function BlogImageExtractor(description) {
    // Create a DOM parser
    const parser = new DOMParser();

    // Parse the HTML string into a document
    const doc = parser.parseFromString(description, 'text/html');

    // Find the first img tag
    const img = doc.querySelector('img');

    // Return the src attribute if img tag exists, else null
    return img ? img.src : null;
}
export function ExtractTextFromHTML(htmlContent) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;

    // Extract text content and sanitize
    let extractedText = tempDiv.textContent || tempDiv.innerText || '';
    extractedText = extractedText.replace(/\s+/g, ' ').trim(); // Normalize spaces

    // Limit to 200 characters
    if (extractedText.length > 150) {
        extractedText = extractedText.slice(0, 200) + '...'; // Add ellipsis at the end
    }

    return extractedText;
}
