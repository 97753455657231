import React from 'react';
import Tilty from 'react-tilty';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';

const showCounter = false;
const CaseStudyProp = ({workSummaryData}) => {

    const CaseLeftThumb = ({data}) => {
        return (<>
            <div className="col-lg-6">
                <div className={`case-study-featured-thumb thumb-${data.id}`}>
                    <Tilty perspective={2000}>
                        <img src={process.env.PUBLIC_URL + "/images/nara-projects/" + data.thumbnail} alt="Case Study"/>
                    </Tilty>
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="case-study-featured">
                    <div className="section-heading heading-left">
                        <span className="subtitle">{data.subtitle}
                            <b> for <i><a href={data.client_web + process.env.REACT_APP_LOCAL_REF}
                                          target="_blank" rel="noreferrer">{data.client}</a></i></b>
                        </span>
                        <h4 className="title">{data.title}</h4>
                        <div dangerouslySetInnerHTML={{__html: data.description}}></div>
                    </div>
                    {showCounter && (
                        <div className="case-study-counterup">
                            <div className="single-counterup">
                                <div className="count-number h3">
                                    <TrackVisibility once>
                                        {({isVisible}) => (<span className="number count">
                                        {isVisible ? <CountUp end={data.count_roi} duration={1}/> : null}
                                    </span>)}
                                    </TrackVisibility>
                                    <span className="symbol">%</span>
                                </div>
                                <span className="counter-title">ROI increase</span>
                            </div>
                            <div className="single-counterup">
                                <div className="count-number h3">
                                    <TrackVisibility once>
                                        {({isVisible}) => (<span className="number count">
                                        {isVisible ? <CountUp end={data.count_visit} duration={1}/> : null}
                                    </span>)}
                                    </TrackVisibility>
                                    <span className="symbol">+</span>
                                </div>
                                <span className="counter-title">Monthly website visits</span>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </>)
    }

    const CaseRightThumb = ({data}) => {
        return (<>
            <div className="col-lg-6 offset-xl-1 order-lg-2">
                <div className={`case-study-featured-thumb thumb-${data.id}`}>
                    <Tilty perspective={2000}>
                        <img src={process.env.PUBLIC_URL + "/images/nara-projects/" + data.thumbnail} alt="Case Study"/>
                    </Tilty>
                </div>
            </div>
            <div className="col-lg-5 order-lg-1">
                <div className="case-study-featured">
                    <div className="section-heading heading-left">
                        <span className="subtitle">{data.subtitle}
                            <b> for <i><a href={data.client_web + process.env.REACT_APP_LOCAL_REF}
                                          target="_blank" rel="noreferrer" >{data.client}</a></i></b>
                        </span>
                        <h4 className="title">{data.title}</h4>
                        <div dangerouslySetInnerHTML={{__html: data.description}}></div>
                    </div>
                    {showCounter && (
                        <div className="case-study-counterup">
                            <div className="single-counterup">
                                <div className="count-number h3">
                                    <TrackVisibility once>
                                        {({isVisible}) => (<span className="number count">
                                        {isVisible ? <CountUp end={data.count_roi} duration={1}/> : null}
                                    </span>)}
                                    </TrackVisibility>
                                    <span className="symbol">%</span>
                                </div>
                                <span className="counter-title">ROI increase</span>
                            </div>
                            <div className="single-counterup">
                                <div className="count-number h3">
                                    <TrackVisibility once>
                                        {({isVisible}) => (<span className="number count">
                                        {isVisible ? <CountUp end={data.count_visit} duration={1}/> : null}
                                    </span>)}
                                    </TrackVisibility>
                                    <span className="symbol">+</span>
                                </div>
                                <span className="counter-title">Monthly website visits</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>)
    }

    return (<>
        {workSummaryData.map((data, index) => (<div className="row" key={index}>
            {(index % 2 === 0) ? <CaseRightThumb data={data}/> : <CaseLeftThumb data={data}/>}
        </div>))}
    </>)
}

export default CaseStudyProp;