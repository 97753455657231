import React from 'react';
import "../../assets/custom-nara.css"

const ServiceProp = ({colSize, serviceStyle, serviceData}) => {
    const defaultImg = process.env.PUBLIC_URL + "/images/resume/nara-default.png"

    return (
        <>
            {serviceData.map((data, index) => (
                <div className={colSize} key={index}>
                    <div className={`services-grid ${serviceStyle}`}>
                        <div className="thumbnail">
                            <img className="thumbnail-img-custom"
                                 src={process.env.PUBLIC_URL + "/images/resume/" + data.institute_logo || defaultImg}
                                 alt="icon"/>
                        </div>
                        <div className="content">
                            <h5 className="title title-custom">{data.title}</h5>
                            <p className="institute-name-custom"><a href={data.institute_url + process.env.REACT_APP_LOCAL_REF}>{data.institute}</a> - {data.institute_location}</p>
                            <p className="institute-name-custom">{data.from_date} - {data.to_date}</p>
                            <p className="responsibilities-custom"
                               dangerouslySetInnerHTML={{__html: data.responsibilities}}></p>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default ServiceProp;