import React, { useEffect } from 'react';
import { FaLightbulb, FaMoon } from 'react-icons/fa';

const SwitcherHeader = () => {
    // Check the stored theme in localStorage on initial load
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            // Apply saved theme from localStorage if available
            document.body.classList.toggle('active-dark-mode', savedTheme === 'dark');
        } else {
            // If no saved theme, apply system default
            const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
            document.body.classList.toggle('active-dark-mode', prefersDark);
        }
    }, []);

    const switchColor = () => {
        // Toggle the dark mode class
        document.body.classList.toggle('active-dark-mode');

        // Save the theme choice in localStorage
        const currentTheme = document.body.classList.contains('active-dark-mode') ? 'dark' : 'light';
        localStorage.setItem('theme', currentTheme);
    };

    return (
        <button onClick={switchColor}>
      <span className="setColor dark">
        <FaLightbulb />
      </span>
            <span className="setColor light">
        <FaMoon />
      </span>
        </button>
    );
};

export default SwitcherHeader;
