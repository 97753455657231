import React, {useEffect, useState} from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import BlogListOne from '../../component/blog/BlogListOne';
import {fetchAPIData, fetchFirestoreData} from "../../common/DataFetcher";
import LoadingSpinner from "../../common/LoadingSpinner";
import {Link} from "react-router-dom";
import "../../assets/custom-nara.css"

const BlogOne = () => {
    const [blogDataList, setBlogDataList] = useState(null);  // Initialize as null
    const [blogInformation, setBlogInformation] = useState(null);  // Initialize as null
    const [loading, setLoading] = useState(true);  // Loading state
    const [error, setError] = useState(null);      // Error state

    // Fetch "blog_information" data
    useEffect(() => {
        const unsubscribe = fetchFirestoreData('blog_information', setBlogInformation);

        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []);

    // Fetch data from API after receiving feed_url from Firestore
    useEffect(() => {
        const getData = async () => {
            if (!blogInformation || !blogInformation[0]?.feed_url) {
                // If blogInformation is null or feed_url is missing, return early
                return;
            }

            try {
                const feedUrl = blogInformation[0]?.feed_url;  // Get the feed_url from Firestore
                const apiResult = await fetchAPIData(feedUrl); // Call the API using the feed_url
                if (Array.isArray(apiResult.items)) {
                    setBlogDataList(apiResult.items);
                } else {
                    throw new Error("Data from API is not an array");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            } finally {
                setLoading(false);  // Set loading to false after fetch attempt
            }
        };

        getData();
    }, [blogInformation]);  // Trigger effect when blogInformation changes

    if (loading) {
        return <LoadingSpinner/>;  // Show loading message
    }

    if (error) {
        return <p>Error loading projects: {error}</p>;  // Show error message if fetch fails
    }

    return (
        <div className="section custom-section section-padding-equal">
            <div className="container">
                <SectionTitle
                    subtitle="Explorations in Tech, Life, and Growth"
                    title="Nara's Journal by Kavindu Narathota"
                    description="Explore practical tips, inspiring stories, and insights that blend technology with real-life experiences, fueling your journey of learning and growth."
                    textAlignment=""
                    textColor=""
                />
                <div className="row g-0">
                    {blogDataList.length > 0 ? (
                        <BlogListOne colSize="col-xl-6" itemShow="4" blogPosts={blogDataList}/>
                    ) : (
                        <LoadingSpinner/> // Show loading text if no data is fetched
                    )}
                </div>

                <div className="more-project-btn custom-nara-more-project-btn">
                    <Link to={process.env.PUBLIC_URL + "/blog"} className="axil-btn btn-borderd btn-large">
                        Discover More Articles by Kavindu</Link>
                </div>
            </div>
            <ul className="shape-group-1 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                                                   alt="bubble"/></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"}
                                                   alt="bubble"/></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-2.png"}
                                                   alt="bubble"/></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                                                   alt="bubble"/></li>
            </ul>
        </div>
    )
}

export default BlogOne;