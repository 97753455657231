import React, { useEffect, useState } from 'react';
import { FaLightbulb, FaMoon } from 'react-icons/fa';

const ColorSwitcher = () => {
    const [theme, setTheme] = useState(null);

    // Check the local storage and fall back to the user's system theme
    useEffect(() => {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
            // Use stored theme preference if available
            setTheme(storedTheme);
            document.body.classList.toggle("active-dark-mode", storedTheme === "dark");
        } else {
            // If no stored theme, use the system preference
            const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
            const initialTheme = systemPrefersDark ? 'dark' : 'light';
            setTheme(initialTheme);
            document.body.classList.toggle("active-dark-mode", initialTheme === "dark");
        }
    }, []);

    const switchColor = () => {
        // Toggle theme and update both body class and localStorage
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
        document.body.classList.toggle("active-dark-mode", newTheme === 'dark');
    };

    return (
        <div className="my_switcher d-none d-lg-block">
            <button onClick={switchColor}>
                <span className="setColor dark"><FaLightbulb /></span>
                <span className="setColor light"><FaMoon /></span>
            </button>
        </div>
    );
};

export default ColorSwitcher;
