import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {FaLinkedin} from "react-icons/fa";
import {FaMedium} from "react-icons/fa6";
import {fetchFirestoreData} from "../DataFetcher";


const Nav = () => {
    const [navbarVisibilityList, setNavbarVisibilityList] = useState([]);

    useEffect(() => {
        const unsubscribe = fetchFirestoreData('navbar_visibility', setNavbarVisibilityList);

        return () => {
            unsubscribe(); // Clean up the listener when the component unmounts
        };
    }, []);
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li><Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/summary"}>Professional Highlights</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/resume"}>Resume</Link></li>
                {/*<li><Link to={process.env.PUBLIC_URL + "/contact"}>PM Resources</Link></li>*/}
                <li><a href={"https://blog.narathota.com/" + process.env.REACT_APP_LOCAL_REF} target="_blank"
                       rel="noreferrer"><FaMedium/></a></li>
                <li><a href={"https://www.linkedin.com/in/narathota/" + process.env.REACT_APP_LOCAL_REF} target="_blank"
                       rel="noreferrer"><FaLinkedin/></a></li>
            </ul>
        </nav>
    )
}

export default Nav;