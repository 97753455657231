import React, {useEffect, useState} from 'react';
import SEO from '../common/SEO';
import BannerThree from '../component/banner/BannerThree';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import BrandOne from '../component/brand/BrandOne';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderOne from "../common/header/HeaderOne";
import AboutFour from "../component/about/AboutFour";
import ScrollToTopButton from "../common/ScrollToTopButton";
import BlogOne from "../component/blog/BlogOne";
import ServiceNara from "../component/service/ServiceNara";
import {fetchFirestoreData} from "../common/DataFetcher";
import LoadingSpinner from "../common/LoadingSpinner";

const PersonalPortfolio = () => {
    const [homePageVisibilityList, setHomePageVisibilityList] = useState([]);

    useEffect(() => {
        const unsubscribe = fetchFirestoreData('homepage_visibility', setHomePageVisibilityList);

        return () => {
            unsubscribe(); // Clean up the listener when the component unmounts
        };
    }, []);

    // Mapping section keys to components
    const componentMapping = {
        navbar: <HeaderOne />,
        "header-banner": <BannerThree />,
        about: <AboutFour />,
        services: <ServiceNara />,
        clients: <BrandOne />,
        blogs: <BlogOne />,
        footer: <FooterTwo />,
    };

    // Filter and sort sections based on "published" and "order"
    const sortedVisibleSections = homePageVisibilityList
        .filter(item => item.published) // Only include published items
        .sort((a, b) => a.order - b.order); // Sort by order

    return (
        <>
            <SEO
                title={`Home - ${process.env.REACT_APP_OWNER}`}
                name={process.env.REACT_APP_OWNER}
                description={process.env.REACT_APP_SEO_DESCRIPTION}
                keywords={process.env.REACT_APP_SEO_KEYWORDS}
                author={`${process.env.REACT_APP_OWNER} | ${process.env.SEO_TAG}`}
                image={process.env.REACT_APP_SEO_IMAGE}
            />
            <ColorSwitcher />
            <ScrollToTopButton />
            {homePageVisibilityList.length > 0 ? (
                <main className="main-wrapper">
                    {sortedVisibleSections.map(section => (
                        <div key={section.id}>
                            {componentMapping[section.section] || null}
                        </div>
                    ))}
                    <ScrollToTopButton />
                </main>
            ) : (
                <LoadingSpinner /> // Show loading spinner if no data is fetched
            )}
        </>
    );
};



export default PersonalPortfolio;

