import React from 'react';


const FooterTwo = ({parentStyle}) => {

    return (
        <footer className={`footer-area ${parentStyle}`}>
            <div className="container">
                <div className="footer-bottom">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by&nbsp;
                                    <a href="https://narathota.com/">{process.env.REACT_APP_OWNER} | {process.env.REACT_APP_SEO_TAG}</a></span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    {/*<li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>*/}
                                    {/*<li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterTwo;