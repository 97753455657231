import React from 'react';
import {Link} from 'react-router-dom';

const WidgetCategory = ({BlogCategories}) => {

    return (
        <ul className="category-list list-unstyled">
            {BlogCategories.slice(0, 10).map((data ,index) => (
                <li key={index}>
                    <Link to={`https://medium.com/tag/${data}`} target="_blank">{data}</Link>
                </li>
            ))}
        </ul>
    )
}

export default WidgetCategory;