import React from 'react'
import PropTypes from "prop-types";


const SEO = ({title, description, name, type, keywords, author, image}) => {
    return (
        <>
            <meta charSet="utf-8"/>
            <meta name="robots" content="noindex, follow"/>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>

            <title>{title}</title>
            <meta name='description' content={description}/>
            <meta property="og:type" content={type}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content={image}/>
            <meta name="twitter:creator" content={name}/>
            <meta name="twitter:card" content={type}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="keywords" content={keywords}/>
            <meta name="author" content={author}/>
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;