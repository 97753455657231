import React from 'react';
import {Link} from 'react-router-dom';
import {FaAngleRight} from "react-icons/fa";
import {BlogImageExtractor, ExtractTextFromHTML} from "../../common/DataExtractor";
import "../../assets/custom-nara.css"


const BlogListOne = ({colSize, itemShow, blogPosts}) => {
    return (<>
        {blogPosts.slice(0, itemShow).map((data, index) => (
            <div className={`${colSize}`} key={index}>
                <div className={`blog-list ${(data.id % 2 === 0) ? "border-start" : ""}`}>
                    <div className="post-thumbnail custom-nara-small-blog-thumbnail-div">
                        <Link to={data.link} target={"_blank"}>
                            <img src={BlogImageExtractor(data.description) || "/images/blog/thumb_5.png"}
                                 alt="Blog Post" className="custom-nara-small-blog-thumbnail-img"/>
                        </Link>
                    </div>
                    <div className="post-content">
                        <h5 className="title">
                            <Link to={data.link} target={"_blank"}>
                                {data.title}
                            </Link>
                        </h5>
                        <p className="">{ExtractTextFromHTML(data.content)}</p>
                        <Link to={data.link} target={"_blank"} className="more-btn">
                            Read more <FaAngleRight/>
                        </Link>
                    </div>
                </div>
            </div>))}
    </>)
}

export default BlogListOne;