import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {FaAngleLeft, FaAngleRight, FaArrowLeft, FaArrowRight} from "react-icons/fa";
import {BlogImageExtractor, ExtractTextFromHTML} from "../../common/DataExtractor";
import "../../assets/custom-nara.css"
import ReactPaginate from "react-paginate";

const BlogGridOne = ({mediumBlogPostList}) => {
    function SlickNextArrow(props) {
        const {className, onClick} = props;
        return (
            <div className={className} onClick={onClick}><FaAngleRight/></div>
        );
    }

    function SlickPrevArrow(props) {
        const {className, onClick} = props;
        return (
            <div className={className} onClick={onClick}><FaAngleLeft/></div>
        );
    }

    var slideSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SlickNextArrow/>,
        prevArrow: <SlickPrevArrow/>,

    }

    const [pageNumber, setPageNumber] = useState(0);

    const blogsPerPage = 3;
    const pageVisited = pageNumber * blogsPerPage;

    const pageCount = Math.ceil(mediumBlogPostList.length / blogsPerPage);

    const changePage = ({selected}) => {
        setPageNumber(selected);
    }

    function formatDate(inputDate) {
        // Create a new Date object from the input string
        const date = new Date(inputDate);

        // Extract the date components
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours24 = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        // Determine AM/PM
        const period = hours24 >= 12 ? 'PM' : 'AM';

        // Convert to 12-hour format
        const hours12 = hours24 % 12 || 12;

        // Return the formatted string
        return `${year}-${month}-${day} ${hours12}:${minutes} ${period}`;
    }


    return (
        <>
            {mediumBlogPostList.slice(pageVisited, pageVisited + blogsPerPage).map((data, index) => (
                <div className="blog-grid" key={index}>
                    <h3 className="title">
                        <Link to={data.link} target={"_blank"}>
                            {data.title}
                        </Link>
                    </h3>
                    <div className="author">
                        <div className="author-thumb">
                            <img
                                src={process.env.PUBLIC_URL + "/images/nara/headshot1.png" || "/images/blog/author-1.png"}
                                alt="Blog Author" className="custom-nara-author-thumb"/>
                        </div>
                        <div className="info">
                            <h6 className="author-name">
                                <Link to={"https://blog.narathota.com"}>{data.author}</Link>
                            </h6>
                            <ul className="blog-meta list-unstyled">
                                <li>{formatDate(data.pubDate)}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="post-thumbnail">
                        <Link to={data.link} target={"_blank"}><img
                            src={BlogImageExtractor(data.description) || "/images/blog/large_thumb_5.png"} alt="Blog"/></Link>
                    </div>
                    <p className="">{ExtractTextFromHTML(data.content)}</p>
                    <Link className="axil-btn btn-borderd btn-large"
                          to={data.link} target={"_blank"}>Read More</Link>
                </div>
            ))}
            <ReactPaginate
                previousLabel={<FaArrowLeft/>}
                nextLabel={<FaArrowRight/>}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"pagination justify-content-start"}
                previousLinkClassName={"prev"}
                nextLinkClassName={"next"}
                disabledClassName={"disabled"}
                activeClassName={"current"}
            />

        </>
    )
}

export default BlogGridOne;