import React from 'react';
import {Link} from 'react-router-dom';
import WidgetPost from './widget/WidgetPost';
import WidgetCategory from './widget/WidgetCategory';
import {
    FaFacebookF, FaInstagram, FaYoutube
} from "react-icons/fa";
import {FaMedium, FaUserGroup, FaXTwitter} from "react-icons/fa6";
import LoadingSpinner from "../../common/LoadingSpinner";

const BlogSidebar = ({BlogSocials, BlogCategoriesList, BlogReadingList}) => {

    // Function to get the corresponding icon for each social link
    const getIcon = (title) => {
        switch (title) {
            case 'Facebook':
                return <FaFacebookF/>;
            case 'Twitter':
                return <FaXTwitter/>;
            case 'Instagram':
                return <FaInstagram/>;
            case 'YouTube':
                return <FaYoutube/>;
            case 'Medium':
                return <FaMedium/>;
            default:
                return <FaUserGroup/>;
        }
    };
    return (
        <div className="axil-sidebar">
            {/*<div className="widget widget-search">*/}
            {/*    <h4 className="widget-title">Search</h4>*/}
            {/*    <form action="#" className="blog-search">*/}
            {/*        <input type="text" placeholder="Search…"/>*/}
            {/*        <button className="search-button"><FaSearch/></button>*/}
            {/*    </form>*/}
            {/*</div>*/}

            <div className="widget widge-social-share">
                <div className="blog-share">
                    <h5 className="title">Follow Nara's Journal:</h5>
                    {BlogSocials.length > 0 ? (
                        <ul className="social-list list-unstyled">
                            {BlogSocials.map((item, index) => {
                                // Check if the item is published before rendering it
                                if (item.published) {
                                    return <li key={index}>
                                        <Link to={item.url} target="_blank"
                                              title={item.title}>{getIcon(item.icon)}</Link>
                                    </li>;
                                }
                                return null; // Return null if not published (nothing will be rendered)

                            })}
                        </ul>
                    ) : (
                        <LoadingSpinner/> // Show loading text if no data is fetched
                    )}
                </div>
            </div>
            <div className="widget widget-recent-post">
                <h4 className="widget-title">Reading Lists</h4>
                <WidgetPost blogReadings={BlogReadingList}/>
            </div>
            <div className="widget widget-categories">
                <h4 className="widget-title">Categories</h4>
                <WidgetCategory BlogCategories={BlogCategoriesList}/>
            </div>
            {/*<div className="widget widget-banner-ad">*/}
            {/*    <Link to="#">*/}
            {/*        <img src={process.env.PUBLIC_URL + "/images/banner/widget-banner.png"} alt="banner"/>*/}
            {/*    </Link>*/}
            {/*</div>*/}
        </div>
    )
}

export default BlogSidebar;